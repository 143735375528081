<script setup lang="ts">
import type { MenuItem } from "~/models/MenuItem";
import { routes } from "~/constants";

const { isDesktop } = useDevice();
const route = useRoute();

const { menu, partner } = defineProps<{
  menu?: Array<MenuItem>;
  partner?: string;
}>();

const activeItemIndex = ref(-1);
const isOpen = ref(false);

const toggleActive = (index: number) => {
  if (index === activeItemIndex.value && isOpen.value) {
    isOpen.value = false;
    activeItemIndex.value = -1;
  } else {
    activeItemIndex.value = index;
    isOpen.value = true;
  }
};

const isPageHome = computed(() => {
  return route.path === routes.homeUrl;
});

const isPageCandidate = computed(() => {
  const data = {
    url:
      route.path === routes.candidatUrl.index
        ? routes.candidatWebAppUrl
        : routes.candidatUrl.index,
    external: route.path === routes.candidatUrl.index,
    isCurrentPage: route.path === routes.candidatUrl.index,
  };
  return data;
});

const isPageEtab = computed(() => {
  const data = {
    url:
      route.path === routes.recruteurUrl.index
        ? routes.etabWebAppUrl.baseUrl + routes.etabWebAppUrl.signup
        : routes.recruteurUrl.index,
    external: route.path === routes.recruteurUrl.index,
    isCurrentPage: route.path === routes.recruteurUrl.index,
  };
  return data;
});

function isCurrentPath(menuItemPath: string): boolean {
  return route.path === menuItemPath;
}

function handleLinkClick(menuItem, index) {
  if (menuItem.sub_menu) {
    toggleActive(index);
  }
}
</script>
<template>
  <header class="header-nav">
    <div class="header-nav-wrapper">
      <NuxtLink to="/">
        <img src="/img/logo_extracadabra_full.svg" alt="logo extracadabra" />
      </NuxtLink>
      <template v-if="menu">
        <nav v-if="isDesktop" role="navigation">
          <ul class="header-nav-list">
            <li
              v-for="menuItem in menu"
              :key="menuItem.path"
              :style="
                menuItem.background ? { 'background-color': menuItem.background } : {}
              "
              class="header-nav-list-item"
              :class="{
                submenu: menuItem.sub_menu,
                open: menuItem.sub_menu && isOpen,
                current: isCurrentPath(menuItem.path),
              }"
            >
              <CustomNuxtLink
                v-if="!menuItem.sub_menu"
                :to="menuItem.path"
                :target="menuItem.external ? '_blank' : '_self'"
                :no-utm="!menuItem.external"
                class="header-nav-list-item-link"
              >
                <div class="level-1">
                  <i v-if="menuItem.icon" :class="menuItem.icon" role="presentation" />
                  <span>{{ menuItem.label }}</span>
                </div>
              </CustomNuxtLink>
              <span v-else class="header-nav-list-item-link">
                <div class="level-1">
                  <i v-if="menuItem.icon" :class="menuItem.icon" role="presentation" />
                  <CustomNuxtLink
                    :to="menuItem.path"
                    :target="menuItem.external ? '_blank' : '_self'"
                    :no-utm="!menuItem.external"
                    class="header-nav-list-item-link"
                  >
                    <span>{{ menuItem.label }}</span>
                  </CustomNuxtLink>
                </div>
                <ul class="level-2">
                  <li
                    v-for="submenuItem in menuItem.sub_menu"
                    :key="submenuItem.label"
                    :class="{
                      [submenuItem.type as string]: submenuItem.type,
                    }"
                  >
                    <CustomNuxtLink
                      :to="submenuItem.path"
                      :target="submenuItem.external ? '_blank' : '_self'"
                      :no-utm="!submenuItem.external"
                    >
                      <span>{{ submenuItem.label }}</span>
                    </CustomNuxtLink>
                  </li>
                </ul>
              </span>
            </li>
          </ul>
        </nav>
        <nav v-else role="navigation">
          <div id="menuToggle">
            <input type="checkbox" />
            <span></span>
            <span></span>
            <span></span>
            <div id="menu-overlay">
              <ul id="menu">
                <li v-for="(menuItem, index) in menu" :key="menuItem.path">
                  <NuxtLink
                    v-if="!menuItem.sub_menu"
                    :to="menuItem.path"
                    :target="menuItem.external ? '_blank' : '_self'"
                    class="item-link"
                    :class="{
                      submenu: menuItem.sub_menu,
                      open: index === activeItemIndex,
                      withBackground: menuItem.background,
                    }"
                    :style="
                      menuItem.background
                        ? { 'background-color': menuItem.background }
                        : {}
                    "
                    :no-utm="!menuItem.external"
                    @click="handleLinkClick(menuItem, index)"
                  >
                    <div class="level-1">
                      <i v-if="menuItem.icon" :class="menuItem.icon" />
                      <span>{{ menuItem.label }}</span>
                    </div>
                  </NuxtLink>
                  <span
                    v-else
                    class="item-link"
                    :class="{
                      submenu: menuItem.sub_menu,
                      open: index === activeItemIndex,
                      withBackground: menuItem.background,
                    }"
                    :style="
                      menuItem.background
                        ? { 'background-color': menuItem.background }
                        : {}
                    "
                    @click="handleLinkClick(menuItem, index)"
                  >
                    <div class="level-1">
                      <i v-if="menuItem.icon" :class="menuItem.icon" />
                      <NuxtLink
                        v-if="menuItem.path"
                        :to="menuItem.path"
                        :target="menuItem.external ? '_blank' : '_self'"
                        class="item-link"
                        :class="{
                          submenu: menuItem.sub_menu,
                          open: index === activeItemIndex,
                          withBackground: menuItem.background,
                        }"
                        :no-utm="!menuItem.external"
                      >
                        <span>{{ menuItem.label }}</span>
                      </NuxtLink>
                      <span v-else>{{ menuItem.label }}</span>
                      <i role="presentation" class="plus extra-plus"></i>
                      <i role="presentation" class="minus extra-minus"></i>
                    </div>
                    <ul v-if="menuItem.sub_menu" class="level-2">
                      <li
                        v-for="submenuItem in menuItem.sub_menu"
                        :key="submenuItem.label"
                      >
                        <NuxtLink
                          :to="submenuItem.path"
                          :target="submenuItem.external ? '_blank' : '_self'"
                          :no-utm="!menuItem.external"
                        >
                          <span
                            :style="submenuItem.color ? { color: submenuItem.color } : {}"
                            >{{ submenuItem.label }}</span
                          >
                        </NuxtLink>
                      </li>
                    </ul>
                  </span>
                </li>
              </ul>
              <div class="container-button">
                <ButtonLink
                  v-if="isPageCandidate.isCurrentPage || isPageHome"
                  :url="isPageCandidate.url"
                  type="candidat"
                  :label="$t('MAIN.MENU.CTA.@CANDIDATES')"
                  size="large"
                  :reading-button="false"
                  :external="isPageCandidate.external"
                />
                <ButtonLink
                  v-if="isPageEtab.isCurrentPage || isPageHome"
                  :url="isPageEtab.url"
                  type="recruteur"
                  :label="$t('MAIN.MENU.CTA.@ETAB')"
                  size="large"
                  :reading-button="false"
                  :external="isPageEtab.external"
                />
              </div>
            </div>
          </div>
        </nav>
      </template>
      <template v-if="partner">
        <img class="header-nav-partner-logo" :src="partner" alt="logo partenaire" />
      </template>
    </div>
  </header>
</template>
<style lang="scss" scoped>
.header-nav {
  background-color: $primary-color;
  height: $menu-height;
  color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  @include header-shadow();
  @include mq(desktop) {
    padding: 0 0 0 1rem;
  }
  @include mq(wide) {
    padding: 0 3rem;
  }
  &-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    max-width: 1130px;
    margin: 0 auto;
    justify-content: inherit;
    @include mq(desktop) {
      max-width: 1300px;
    }
  }
  &-partner-logo {
    position: absolute;
    right: 0;
    @include mq(desktop) {
      position: inherit;
    }
  }
  img {
    min-width: 104px;
    max-height: 50px;
  }

  &-list {
    display: flex;
    width: 100%;
    height: 100%;
    margin-left: $gutter * 4;
    @include mq(desktop) {
      justify-content: center;
      margin-left: $gutter * 2;
    }
    &-item {
      display: flex;
      flex-wrap: wrap;
      padding: 0 $gutter * 3;
      border-left: 1px solid $primary-color-400;
      width: 100%;
      @include mq(desktop) {
        width: auto;
        padding: 0 var(--showcase-size-200);
        text-align: center;
      }
      &-link {
        text-decoration: none;
        color: $white;
        display: flex;
        align-items: center;
        padding-left: $gutter * 3;
        justify-content: center;
        width: 100%;
        @include mq(desktop) {
          padding-left: 0;
        }
        i {
          display: flex;
          margin-right: $gutter;
          font-size: 22px;
        }
        span {
          font-size: 14px;
        }
      }
      &:hover,
      &.current {
        background-color: $white;
        a,
        .header-nav-list-item-link {
          color: $primary-color;
        }
      }
      &.submenu {
        padding: 0;
        @include mq(desktop) {
          min-width: 185px;
        }
        .level-1 {
          display: flex;
          align-items: center;
        }
        .level-2 {
          display: none;
        }
        a,
        .header-nav-list-item-link {
          display: flex;
          flex-wrap: wrap;
          position: relative;
          padding: 0 $gutter * 2;
          ul {
            display: block;
            position: absolute;
            top: $menu-height;
            left: 0;
            max-height: 0;
            opacity: 0;
            overflow: hidden;
            transition: all ease-in-out 0.2s;
            width: max-content;
            padding: 0;
            background-color: $white;
            li {
              width: fit-content;
              border-bottom: 1px solid var(--showcase-color-grey-100);
              a,
              .header-nav-list-item-link {
                padding: $gutter * 3;
                text-decoration: none;
                span {
                  color: $white;
                }
              }
              &.candidat {
                background-color: $primary-color;
                span {
                  color: var(--showcase-color-additional-light);
                }
                &:hover {
                  background-color: $white;
                  a,
                  .header-nav-list-item-link {
                    span {
                      color: $primary-color;
                    }
                  }
                }
              }
              &.recruteur {
                background-color: $etab-color;
                span {
                  color: var(--showcase-color-additional-light);
                }
                &:hover {
                  background-color: $white;
                  a,
                  .header-nav-list-item-link {
                    span {
                      color: $etab-color;
                    }
                  }
                }
              }
            }
          }
        }

        &:hover {
          background-color: $white !important;
          cursor: pointer;
          a,
          .header-nav-list-item-link {
            color: $primary-color;
          }
          ul {
            max-height: 500px;
            opacity: 1;
            transition: all ease-in-out 0.2s;
            display: block;
            background-color: var(--showcase-color-additional-light);
            width: 100%;
            li {
              width: 100%;
              a {
                display: flex;
                padding: var(--showcase-size-125);
                justify-content: center;
                span {
                  color: var(--showcase-color-additional-dark);
                }
              }
              &:hover {
                background-color: var(--showcase-color-primary-200);
                a {
                  span {
                    color: var(--showcase-color-additional-light);
                  }
                }
              }
            }
          }
        }
      }
      &.withBackground {
        color: $white;
      }
    }
  }
  nav {
    height: 65px;
    @include mq(desktop) {
      height: 100%;
      width: 100%;
    }
  }
  .extra-team {
    font-size: 30px;
  }
  .extra-menu-book {
    font-size: 27px;
  }
  #menuToggle {
    display: flex;
    flex-direction: column;
    position: relative;
    top: 25px;
    right: 0;
    z-index: 1;
    -webkit-user-select: none;
    user-select: none;
    input {
      display: flex;
      width: 40px;
      height: 32px;
      position: absolute;
      cursor: pointer;
      opacity: 0;
      z-index: 2;
    }
    span {
      display: flex;
      width: 29px;
      height: 2px;
      margin-bottom: 5px;
      position: relative;
      background: #ffffff;
      border-radius: 3px;
      z-index: 1;
      transform-origin: 5px 0px;
      transition:
        transform 0.2s cubic-bezier(0.77, 0.2, 0.05, 1),
        background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
        opacity 0.55s ease;
      &:first-child {
        transform-origin: 0% 0%;
      }
      &:nth-last-child(2) {
        transform-origin: 0% 100%;
      }
    }
    #menu-overlay {
      background-color: $white;
      width: 100vw;
      height: 100vh;
      position: absolute;
      margin: -50px 0 0 -50px;
      -webkit-font-smoothing: antialiased;
      transform-origin: 0% 0%;
      top: 82px;
      right: -16px;
      transform: translate3d(110vw, 0, 0);
      transition: transform 0.2s cubic-bezier(0.77, 0.2, 0.05, 1);
      #menu {
        display: flex;
        flex-wrap: wrap;
        li {
          padding: 0;
          width: 100%;
          transition-delay: 2s;
          border-bottom: 1px solid $grey-light;
          .item-link {
            display: flex;
            align-items: center;
            color: $dark;
            text-decoration: none;
            text-transform: uppercase;
            font-family: "Poppins";
            font-weight: 400;
            width: 100%;
            height: fit-content;
            margin-bottom: 0;
            i {
              font-size: 22px;
              width: 24px;
              height: 24px;
              min-width: 24px;
              justify-content: center;
              display: flex;
            }
            span {
              border: 0;
              background: none;
              width: 100%;
              height: 100%;
              margin-left: 14px;
              font-size: 14px !important;
              margin-bottom: 0;
              padding: $gutter * 3 0 $gutter * 3 var(--showcase-size-100);
            }
            &.submenu {
              display: flex;
              flex-wrap: wrap;
              .level-2 {
                display: block;
                max-height: 0;
                overflow: hidden;
                transition: all ease-in-out 0.2s;
                width: 100%;
                padding: 0;
                background-color: $white;
                li {
                  width: 100%;
                  border-bottom: 1px solid var(--showcase-color-grey-200);
                  background-color: var(--showcase-color-grey-100);
                  padding-left: var(--showcase-size-100);
                  list-style: decimal;
                  a {
                    text-decoration: none;
                    color: var(--showcase-color-additional-dark);
                  }
                }
              }
              i {
                &.plus {
                  display: flex;
                }
                &.minus {
                }
                display: none;
              }
              &.open {
                background-color: var(--showcase-color-primary-200);
                color: var(--showcase-color-additional-light);
                i {
                  &.minus {
                    display: flex;
                  }
                  &.plus {
                    display: none;
                  }
                }
                .level-1 {
                  & > span {
                    color: var(--showcase-color-additional-light);
                  }
                }
                ul {
                  max-height: 500px;
                  transition: all ease-in-out 0.2s;
                }
              }
              .level-1 {
                display: flex;
                align-items: center;
                display: flex;
                justify-content: space-between;
                width: 100%;
                i {
                  margin-left: $gutter * 3;

                  &[class*="extra-"] {
                    align-items: center;
                    justify-content: center;
                    font-size: 12px;
                    margin-right: var(--showcase-size-100);
                  }

                  & + span {
                    padding-left: 0;
                  }
                }
              }
            }
            &.withBackground {
              color: $white;
            }
          }
        }
      }
    }
    input:checked {
      & ~ span {
        opacity: 1;
        transform: rotate(45deg) translate3d(-3px, -1px, 0);
        background: #fff;
      }
      & ~ span:nth-last-child(3) {
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
      }
      & ~ span:nth-last-child(2) {
        transform: rotate(-45deg) translate3d(0, -1px, 0);
      }
      & ~ #menu-overlay {
        transform: none;
      }
    }
  }
  & + div {
    margin-top: $menu-height;
  }
}

.container-button {
  margin-top: var(--showcase-size-200);
  display: flex;
  justify-content: center;
  padding: 0 var(--showcase-size-100);
  width: 100%;
  flex-wrap: wrap;
  .btn-link {
    width: 100%;
    &:last-child {
      margin-top: var(--showcase-size-125);
    }
  }
}
</style>
